import { PauseIcon, PlayIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import LazyLoad from "react-lazyload";
import { inject, observer } from "mobx-react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export const AudioFragment = inject(
  "player",
  "root"
)(
  observer(({ player, fragment }) => {
    const [audio] = useState(new Audio(fragment.source));

    useEffect(() => {
      audio.addEventListener("ended", (event) => {
        if (player.loop && fragment.playing) {
          event.target.currentTime = 0;
          event.target.play();
        } else {
          fragment.setPlaying(false);
        }
      });
      // audio.addEventListener("timeupdate", (event) => {
      //   console.log(event.target.currentTime + "/" + event.target.duration);
      // });
    }, [audio, fragment, player.loop]);

    const startPlay = () => {
      fragment.setPlaying(true);
      audio.play();
    };

    const stopPlay = () => {
      audio.pause();
      fragment.setPlaying(false);
    };

    const togglePlay = () => {
      if (fragment.playing) {
        stopPlay();
      } else {
        startPlay();
      }
    };

    return (
      <div>
        <div
          className="w-full flex justify-center relative cursor-pointer"
          onPointerEnter={() => fragment.setHover(true)}
          onPointerLeave={() => fragment.setHover(false)}
          onClick={() => togglePlay()}
        >
          <LazyLoad>
            <LazyLoadImage
              alt={fragment.title}
              className="object-cover w-48 h-48 rounded-md drop-shadow-lg "
              effect="blur"
              src={fragment.image}
            />
          </LazyLoad>
          {(fragment.hover || fragment.playing) && (
            <div className="absolute h-full w-full flex justify-center items-center">
              <div className="w-24 h-24 bg-green-800 rounded-full">
                {fragment.playing ? (
                  <PauseIcon className="fill-green-600" />
                ) : (
                  <PlayIcon className="fill-green-600" />
                )}
              </div>
            </div>
          )}
        </div>
        <div className="text-center mt-2">{fragment.title}</div>
      </div>
    );
  })
);
