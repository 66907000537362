import { types } from "mobx-state-tree";
import { Player } from "./Player";
import { fragments } from "../assets/data/fragments";
import { FragmentList } from "./Fragment";
import makeInspectable from "mobx-devtools-mst";

const root = types.model("root", {
  player: Player,
  fragments: FragmentList,
});

const RootStore = root.create({
  player: {
    loop: false,
  },
  fragments: {
    items: fragments,
  },
});

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  makeInspectable(RootStore);
}

export default RootStore;
