import { types } from "mobx-state-tree";

export const Fragment = types
  .model("Fragment", {
    title: types.string,
    image: types.string,
    source: types.string,
    playing: types.maybe(types.boolean),
    hover: types.maybe(types.boolean),
  })
  .actions((self) => ({
    setPlaying(playing) {
      self.playing = playing;
    },
    setHover(hover) {
      self.hover = hover;
    },
  }));

export const FragmentList = types.model("FragmentList", {
  items: types.array(Fragment),
});
