import "./App.css";
import { Provider } from "mobx-react";

import RootStore from "./models/RootStore";
import { Fragments } from "./components/Fragments";
import { PlayerSwitcher } from "./components/PlayerSwitcher";

function App() {
  return (
    <Provider {...RootStore} root={RootStore}>
      <div className="container mx-auto max-w-screen-lg mt-10 p-2">
        <div className="w-full flex flex-col text-center items-center mb-4">
          <img
            className="w-36"
            alt="Zie hoe zij hangt"
            src={require("./assets/image/betula-logo.png")}
          />
          <h1 className="text-4xl mt-2 underline underline-offset-2 text-green-600 hover:text-green-800">
            <a href="https://www.betulapubescens.nl">Betula pubescens</a>
          </h1>
          <h2 className="text-2xl font-bold mb-2">Soundboard</h2>
          <PlayerSwitcher />
        </div>
        <Fragments />
        <div className="mt-4 text-center">
          &copy;Made with ❤️ and ☕️ by{" "}
          <a
            className="underline underline-offset-2 text-green-600 hover:text-green-800"
            href="https://joost.lawerman.me"
          >
            Amice Lawerman
          </a>{" "}
          and Amice Doornenbal for Betula pubescens
        </div>
      </div>
    </Provider>
  );
}

export default App;
