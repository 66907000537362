import { inject, observer } from "mobx-react";

export const PlayerSwitcher = inject(
  "player",
  "root"
)(
  observer(({ player }) => {
    return (
      <div className="text-center text-2xl">
        <span>Modus: </span>
        <select
          onChange={(event) => player.setLoop(event.target.value === "loop")}
        >
          <option value="once">Eenmalig</option>
          <option value="loop">Loop</option>
        </select>
      </div>
    );
  })
);
