export const fragments = [
  {
    title: "Doornenbal: Dat is heel simpel",
    image: require("../image/datisheelsimpel.png"),
    source: require("../audio/datisheelsimpel.mp3"),
  },
  {
    title: "Lawerman: Hou op hou op hou op",
    image: require("../image/houophouophouop.png"),
    source: require("../audio/houophouophouop.mp3"),
  },
  {
    title: "Eigenraam: Art Art Art Art",
    image: require("../image/artartartart.png"),
    source: require("../audio/artartartart.mp3"),
  },
  {
    title: "J. Sies: Die lach",
    image: require("../image/jonathanlach.png"),
    source: require("../audio/jonathanlach.ogg"),
  },
  {
    title: "Osnabrugge: Kut joosten",
    image: require("../image/kutjoosten.png"),
    source: require("../audio/kutjoosten.mp3"),
  },
  {
    title: "Wilders: Ik ben op soos!",
    image: require("../image/bente-op-soos.png"),
    source: require("../audio/bente-op-soos.m4a"),
  },
  {
    title: "Boxhoorn: wooeh",
    image: require("../image/wooeh.png"),
    source: require("../audio/wooeh.m4a"),
  },
  {
    title: "Boxhoorn: weee jeee",
    image: require("../image/boxhoorn_weeejeee.png"),
    source: require("../audio/boxhoorn_weeejeee.m4a"),
  },
  {
    title: "Doornenbal: hahahaha",
    image: require("../image/doornenbal_hahahaha.png"),
    source: require("../audio/doornenbal_hahahaha.m4a"),
  },
  {
    title: "Klapwijk: ik ben een beetje een huissjaars",
    image: require("../image/klapwijk_ik_ben_een_beetje_een_huissjaars.png"),
    source: require("../audio/klapwijk_ik_ben_een_beetje_een_huissjaars.m4a"),
  },
  {
    title:
      "Lawerman: Bram is fucking zat. Doornenbal: laten we zeggen naar de tering",
    image: require("../image/lawerman_bram_is_fucking_zat_doornenbal_laten_we_zeggen_naar_de_tering.png"),
    source: require("../audio/lawerman_bram_is_fucking_zat_doornenbal_laten_we_zeggen_naar_de_tering.m4a"),
  },
  {
    title: "van Dijk: Kan iemand mijn frikandel aanpakken? Alsjeblieft",
    image: require("../image/abel-frikandel.png"),
    source: require("../audio/abel-frikandel.m4a"),
  },
];
