import { types } from "mobx-state-tree";

export const Player = types
  .model("Player", {
    loop: types.boolean,
  })
  .actions((self) => ({
    setLoop(loop) {
      self.loop = loop;
    },
  }));
