import { AudioFragment } from "./AudioFragment";
import { inject, observer } from "mobx-react";

export const Fragments = inject(
  "fragments",
  "root"
)(
  observer(({ fragments }) => {
    return (
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 justify-items-center">
        {fragments.items.map((fragment) => (
          <AudioFragment key={fragment.title} fragment={fragment} />
        ))}
      </div>
    );
  })
);
